<template>
  <div>
    <h4>Buscar Estudiantes</h4>
    <StudentList></StudentList>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "StudentListView",
  components: {
    StudentList: () => import("@/components/reusable/StudentList"),
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      loged_user: "getUser",
    }),
  },
  methods: {},
  created() {},
};
</script>

<style>
</style>